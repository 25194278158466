import React from 'react'
import './BottomNavbar.css'
import { useNavigate } from 'react-router-dom'

function BottomNavbar() {
  const Navigate = useNavigate()
  return (
    <div className='BottomNavbarContainer'>
      <div className='BottomNavbarContainerLeft'>
        <ul>
          <li onClick={()=>{
            Navigate('/who-we-are')
          }}>WHO WE ARE</li>
          <li onClick={()=>{
            Navigate('/what-we-do')
          }}>WHAT WE DO</li>
          <li onClick={()=>{
            Navigate('/how-we-work')
          }}>HOW WE WORK</li>
        </ul>
      </div>
      <div className='BottomNavbarContainerRight'>
        <ul>
          <li onClick={()=>{
            Navigate('/all-articles')
          }}>ARTICLES</li>
          <li onClick={()=>{
            Navigate('/events')
          }}>EVENTS</li>
          <li onClick={()=>{
            Navigate('/resources')
          }}>RESOURCES</li>
          <li onClick={()=>{
            Navigate('/election-calendar')
          }}>ELECTION CALENDAR</li>
          <li onClick={()=>{
            Navigate('/gallery')
          }}>GALLERY</li>
          <li onClick={()=>{
            Navigate('/vote')
          }}>VOTE</li>
          <li onClick={()=>{
            Navigate('/kenya')
          }}>KENYA</li>
        </ul>
      </div>
    </div>
  )
}

export default BottomNavbar
